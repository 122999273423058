import React from 'react';
import { Chrono } from "react-chrono";

export const RoadmapSection = () => {
  return (
    <section id="roadmapSection">
      <div className="container">
        <div className="row">
          <div className="col text-left">
            <h1 className="title">ROADMAP</h1>
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <div id="timeline-chrono-wrapper" style={{ width: "100%", height: "100%" }}>
              <Chrono 
                // activeItemIndex={0}
                disableAutoScrollOnClick={false}
                disableClickOnCircle={true}
                disableNavOnKey={true}
                // cardHeight={200}
                useReadMore={false}
                mode="VERTICAL_ALTERNATING"
                theme={{ 
                  primary: "#b18e72",
                  secondary: "#b18e72",
                  cardBgColor: "#fff",
                  cardForeColor: "#1a1713",
                  titleColor: "#fff"
                }}
                scrollable={{ scrollbar: true }} 
                items={[
                  {
                    title: "01",
                    cardTitle: "Phase 1",
                    cardSubtitle: "",
                    cardDetailedText: "Mint Lil BOB in Feb 2022"
                  },
                  {
                    title: "02",
                    cardTitle: "Phase 2",
                    cardSubtitle: "",
                    cardDetailedText: "Exclusive Merch for Lil BOB holders"
                  },
                  {
                    title: "03",
                    cardTitle: "Phase 3",
                    cardSubtitle: "",
                    cardDetailedText: "3D Lil BOB release for minting"
                  },
                  {
                    title: "04",
                    cardTitle: "Phase 4",
                    cardSubtitle: "",
                    cardDetailedText: "Host webinar/ workshops - support group/ wellness related/ mental health"
                  },
                  {
                    title: "05",
                    cardTitle: "Phase 5",
                    cardSubtitle: "",
                    cardDetailedText: "Initiate community wallet to reward/ support charity voted by Lil BOB DAO and support our community's projects 10% of initial proceeds and 30% of the royalty fees received from secondary market sale will be donated to the selected charity voted by Lil BOB DAO."
                  },
                  {
                    title: "06",
                    cardTitle: "Phase 6",
                    cardSubtitle: "",
                    cardDetailedText: "Lil BOB play - to- earn game launch on Sandbox Metaverse"
                  },
                  {
                    title: "07",
                    cardTitle: "Phase 7",
                    cardSubtitle: "",
                    cardDetailedText: "Team Lil BOB is working on another NFT project who is a friend of Lil BOB. Stay tuned! We will continue to spread out positive energy and happiness to everyone around the world!"
                  },
              ]} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
