import React from 'react';
export const AboutSection = () => {
  return (
    <section id="aboutSection">
      <div className="container">
        <div className="row">
          <div className="col banner_heading title">
            <h1 className="mt-4">Who is Lil BOB?</h1>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 text-left">
            <div className="row">
              <div className="col-md-12 col-sm-12 text-left">
                <p className="paragraph">Lil BOB is Non-binary, gender-neutral.
                <br />Lil BOB is Your best companion.
                <br />Lil BOB is Happiness.
                <br />Lil BOB is Love.
                <br />Lil BOB is Life.
                </p>
                <p className="paragraph">
                  Lil BOB is a little blob of happiness who is always full of energy to cheer you up!
                  <br />We all need this little blob to fight against the world with us at our downtime:
                </p>
                <p className="paragraph">
                  Gyms are closed? Lil BOB will dunk, surf, ski, skate and snowboard with you!
                  <br />Bars are closed? Lil BOB will drink champagne, beer and even soju with you!
                  <br />No more parties? Lil BOB is your little DJ in da house!
                  <br />Lil BOB is cheeky - ze loves dressing up 😏
                </p>
                <p className="paragraph">Lil BOB does everything.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
