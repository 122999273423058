import React from 'react';
import { Accordion } from 'react-bootstrap';
export const FaqSection = () => {
  return (
    <section id="faqSection">
      <div className="container">
        <div className="row title">
          <div className="col text-center">
            <h1>FAQ</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>When can I mint a Lil BOB NFT?</Accordion.Header>
                  <Accordion.Body>
                  Lil BOB collection will be available for whitelist mint on 28 Feb.
                  <br />Public date will be on 2 Mar.
                  <br />More information can be found on Discord.
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header> Where can I purchase a Lil BOB NFT?</Accordion.Header>
                  <Accordion.Body>
                  Lil BOB will only be available for minting on our official site.
                  <br />
                  <br />To avoid scams and hackers. make sure you are visiting http://lilbobnft.nohyp.com – check the URL carefully. 
                  <br />Please check our Discord channel for further information.
                  <br />Please note that We will never DM you on Discord.

                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>How much will 1 Lil BOB NFT cost?</Accordion.Header>
                  <Accordion.Body>
                  <br />Mint Price will be 0.088 ETH for whitelist mints and 0.1 ETH for public mints.
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How many Lil BOB NFTs can I mint?</Accordion.Header>
                  <Accordion.Body>
                  <br />You can mint a maximum of 10 Lil BOB per wallet.
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>What is the total supply?</Accordion.Header>
                  <Accordion.Body>
                  Lil BOB is a collection of 1,111 NFTs.
                  </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header> Which wallets will I be able to use to mint an NFT?</Accordion.Header>
                  <Accordion.Body>
                  You can mint using MetaMask or any wallet supported by WalletConnect.
                  </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};
