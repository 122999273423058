import React from 'react';
import logo from '../assets/logo.png';

export const Header = () => {
  return (
    <header>
      <div className="navbar navbar-expand-lg navbar-light fixed-top">
        <div className="container-fluid d-flex justify-content-between">
          <a href="#" className="navbar-brand d-flex align-items-center">
            <img className="logo" src={logo} />
            {/* <strong style={{ paddingLeft: "10px", color: '#696969' }}>Lil BOB</strong> */}
          </a>
          <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#headerMainMenu">
            <i style={{ color: '#1A1713' }} className="bi bi-list"></i>
          </button>
          <div className="justify-content-end collapse navbar-collapse" id="headerMainMenu">
            <ul className="menus navbar-nav me-3 mb-2 mb-lg-0">
              {/* <li className="nav-item"><a className="nav-link" href="#mintingSection">Mint</a></li> */}
              <li className="nav-item"><a className="nav-link" href="#aboutSection">ABOUT</a></li>
              <li className="nav-item"><a className="nav-link" href="#roadmapSection">ROADMAP</a></li>
              {/* <li className="nav-item"><a className="nav-link" href="#teamSection">Team</a></li> */}
              <li className="nav-item"><a className="nav-link" href="#faqSection">FAQ</a></li>
            </ul>
            <div className="social-link">
              <a href="https://twitter.com/lilbobnft"><i className="bi bi-twitter"></i></a>
              <a href="https://discord.gg/TbnQDS3MQv"><i className="bi bi-discord"></i></a>
              <a href="https://www.instagram.com/lilbobnft"><i className="bi bi-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
