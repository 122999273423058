import React from 'react';
import "bootstrap/dist/js/bootstrap.min.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';

import { Header } from './components/Header';
import { MintingSection } from './components/MintingSection';
import { GallerySection } from './components/GallerySection';
import { CollabSection } from './components/CollabSection';
import { AboutSection } from './components/AboutSection';
import { RoadmapSection } from './components/RoadmapSection';
import { NewsTickerSection } from './components/NewsTickerSection';
import { TeamSection } from './components/TeamSection';
import { FaqSection } from './components/FaqSection';
import { Footer } from './components/Footer';

// import logo from './assets/logo/logo.svg';
import './styles/App.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <MintingSection />
      {/* <CollabSection /> */}
      <GallerySection />
      <AboutSection />
      <RoadmapSection />
      {/* <NewsTickerSection direction="toRight"/>
      <TeamSection />
      <NewsTickerSection direction="toLeft"/> */}
      <FaqSection />
      <Footer />
    </div>
  );
}

export default App;
