import React from 'react';
import main_gif from './../assets/main.gif';
export const MintingSection = () => {
  return (
    <section id="mintingSection">
      <div className="container">
        <div className="row align-items-start h-100">
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-9 col-sm-12 mx-auto left">
            <div className="banner_heading section-1"><h1><b>Lil BOB</b></h1></div>
            {/* <h3 className="slogan">???</h3> */}
            <p className="banner_text mt-3">Lil BOB (ze/zir) is a collection of 1,111 unique NFTs living on the Ethereum Blockchain. 
            Our NFTs are all one of a kind 2D generative art and come with a lifetime membership to an ever-growing community, and integration into Sandbox as a play-to-earn NFT. 
            </p>
            <div className="text_center banner_heading  mb-4">
            <a href="https://lilbobnft-mint.nohyp.com/"><button className="mint_button button">Click Here to Mint!</button></a>
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-9 col-sm-12 banner text-center right">
            <div className="banner_center"><img src={main_gif} className="image-banner" alt="image-banner" /></div>
            {/* <div className="text_center banner_heading  mb-4"><h1 className="mint-message">WL Mint on 28 Feb 2022 at 0.088 ETH <br /> Public Mint on 2 Mar 2022 at 0.1 ETH</h1><p className="m-1 feature-text"></p></div> */}
          </div>
        </div>
      </div>
    </section>
  );
};
