import React from 'react';
import logo from '../assets/logo_footer.png';
export const Footer = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="row asw">
          <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
            <div className="justify-content-start" id="footerLeft">
              <div className="container">
                <div className="row">
                  <div className="col social-link">
                    <a href="https://twitter.com/lilbobnft"><i className="bi bi-twitter"></i></a>
                    <a href="https://discord.gg/TbnQDS3MQv"><i className="bi bi-discord"></i></a>
                    <a href="https://www.instagram.com/lilbobnft"><i className="bi bi-instagram"></i></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 text-right">
            <div id="footerRight">
              <div className="container ">
                <div className="row">
                  <div className="col d-flex flex-row-reverse">
                      <div style={{paddingTop:"15px", textAlign: "center"}}>
                        <img className="logo" src={logo} />
                    {/* <span>No.Hyp</span> */}
                        <br/>
                      <label>©All rights reserved</label>
                      </div>
                      {/* <div style={{padding: "10px 15px"}}>
                        <img className="logo" src={logo} />
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};
