import React from 'react';
import {
  FBR_01,
  FBR_02,
  FBR_03,
  FBR_04,
  FBR_05,
  // FBR_06
} from '../helpers/banner';
import Slider from "react-slick";

export const GallerySection = () => {
  var settings = {
    centerMode: true,
    centerPadding: '100px',
    slidesToShow: 5,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 1000,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 3
        }
      },
      {
        breakpoint: 750,
        settings: {
          arrows: false,
          centerMode: true,
          centerPadding: '50px',
          slidesToShow: 1
        }
      }
    ]
  };
  var width = "200px";
  return (
    <section id="gallerySection">
      <div className="row">
        <div className="col-10 offset-1">
          <Slider {...settings}>
            <div><img src={FBR_01} width={width} /></div>
            <div><img src={FBR_02} width={width} /></div>
            <div><img src={FBR_03} width={width} /></div>
            <div><img src={FBR_04} width={width} /></div>
            <div><img src={FBR_05} width={width} /></div>
            {/* <div><img src={FBR_06} width={width} /></div> */}
          </Slider>
        </div>
      </div>
    </section>
  );
};
